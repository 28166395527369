import { MetaFunction } from 'react-router';

export type GetPageMetaArgs = {
  title?: string;
  url?: string;
  previewTitle?: string;
  previewDescription?: string;
  previewImage?: string;
  previewImageW?: number;
  previewImageH?: number;
  twitterCard?: 'summary_large_image' | 'summary';
  twitterCreator?: string | null;
};

const trimDescription = (str: string) =>
  str.length > 150 ? str.slice(0, 150) + '...' : str;

export const getPageMeta = ({
  title = 'Synoptic: Intelligence network & marketplace',
  url = 'https://synoptic.com',
  previewTitle = title,
  previewDescription = 'Share, analyze, and compete',
  previewImage = 'https://synoptic.com/static/og-main.png',
  previewImageW = 1200,
  previewImageH = 630,

  twitterCard = 'summary_large_image',
  twitterCreator,
}: GetPageMetaArgs = {}) => {
  const description = trimDescription(previewDescription);

  const meta: ReturnType<MetaFunction> = [
    { title },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: previewTitle },
    { property: 'og:description', content: description },
    { property: 'og:url', content: url },
    { property: 'og:image', content: previewImage },
    { property: 'og:image:width', content: previewImageW },
    { property: 'og:image:height', content: previewImageH },
    { name: 'twitter:site', content: '@SynopticCom' },
    { name: 'twitter:card', content: twitterCard },
    { name: 'twitter:title', content: previewTitle },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: previewImage },
  ];

  if (twitterCreator) {
    meta.push({ name: 'twitter:creator', content: twitterCreator });
  }
  return meta;
};
